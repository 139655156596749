import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { CourseContext } from '../../enrolment/CourseContextProvider';
import { EnrolmentContext } from '../EnrolmentContextProvider';
export default function Info() {
    const { courseName, courseDate, courseCost, maximumParticipants, vacancies, courseStartTime, courseDuration, courseAddress } = React.useContext(CourseContext);
    const { enrolmentCost, attendees, isPayer } = React.useContext(EnrolmentContext);
    const products = [
        {
            name: 'Participants',
            desc: `* ${attendees.length > 1 ? attendees.length : 1}`,
            price: `$${courseCost}`,
        }
    ];
    const info = [
        {
            name: 'Course',
            desc: courseName,
            price: '',
        },
        {
            name: 'Date',
            desc: courseDate,
            price: '',
        },
        {
            name: 'Location',
            desc: courseAddress,
            price: ''
        },
        {
            name: 'Start Time',
            desc: courseStartTime,
            price: '',
        },
        {
            name: 'Duration',
            desc: courseDuration,
            price: '',
        },
        {
            name: 'Vacancies',
            desc: `${vacancies} out of ${maximumParticipants} positions are available`,
            price: ''
        },
    ];
    return (_jsxs(React.Fragment, { children: [_jsx(Typography, { variant: "subtitle2", hidden: !isPayer, sx: { color: 'text.secondary' }, children: "Total" }), _jsxs(Typography, { variant: "h4", gutterBottom: true, hidden: !isPayer, children: ["$", enrolmentCost] }), _jsxs(List, { disablePadding: true, children: [isPayer && products.map((product) => (_jsxs(ListItem, { sx: { py: 1, px: 0 }, children: [_jsx(ListItemText, { sx: { mr: 0 }, primary: product.name, secondary: product.desc }), _jsx(Typography, { variant: "body1", sx: { fontWeight: 'medium' }, children: product.price })] }, product.name))), _jsx("hr", { hidden: !isPayer }), info.map((product) => (_jsxs(ListItem, { sx: { py: 1, px: 0 }, children: [_jsx(ListItemText, { sx: { mr: 0 }, primary: product.name, secondary: product.desc }), _jsx(Typography, { variant: "body1", sx: { fontWeight: 'medium' }, children: product.price })] }, product.name)))] })] }));
}
