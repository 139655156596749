import { jsx as _jsx } from "react/jsx-runtime";
import { createRoot } from '@wordpress/element';
import domReady from '@wordpress/dom-ready';
import { CourseProvider } from './enrolment/CourseContextProvider';
import { EnrolmentProvider } from './enrolment/EnrolmentContextProvider';
import Enrolment from './enrolment/Enrolment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ErrorProvider } from './enrolment/ErrorContextProvider';
export const EnrolmentShortcode = () => {
    return (_jsx(LocalizationProvider, { dateAdapter: AdapterDayjs, children: _jsx(ErrorProvider, { children: _jsx(CourseProvider, { children: _jsx(EnrolmentProvider, { children: _jsx(Enrolment, {}) }) }) }) }));
};
domReady(() => {
    const el = document.getElementById('ati-axcelerate-react-enrolment');
    if (!el) {
        console.error('Failed to get root element');
        return;
    }
    const root = createRoot(el);
    root.render(_jsx(EnrolmentShortcode, {}));
});
