var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useContext, useState } from "react";
import { EnrolmentContext } from "../EnrolmentContextProvider";
import { Autocomplete, TextField } from "@mui/material";
export default function ABNLookup() {
    const { organisation, setOrganisation, ABN, setABN } = useContext(EnrolmentContext);
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState({
        "Company Name": organisation,
        "Current Name": organisation,
        ABN: ABN.toString()
    });
    const handleOrgSelect = (event, value) => {
        // If the user pressed the 'x', clear out any saved data
        if (value == null) {
            setOptions([]);
            setInputValue(null);
            setABN(0);
            return;
        }
        const name = value["Current Name"] || value["Company Name"];
        let abn = parseInt(value.ABN);
        if (isNaN(abn))
            abn = 0;
        setInputValue({
            "Company Name": name,
            "Current Name": name,
            ABN: abn.toString()
        });
        setOrganisation(name);
        setABN(abn);
    };
    const handleInputChange = (event, value) => __awaiter(this, void 0, void 0, function* () {
        if (value.length < 3)
            return;
        const customOrg = {
            "Company Name": value,
            "Current Name": value,
            ABN: "0"
        };
        const res = yield fetch("https://data.gov.au/data/api/3/action/datastore_search?" + new URLSearchParams({
            resource_id: "5c3914e6-413e-4a2c-b890-bf8efe3eabf2",
            filters: JSON.stringify({ "Current Name Indicator": "Y" }),
            q: value,
            limit: "10"
        }).toString());
        if (!res.ok) {
            // We dont set the global error message here because if this API goes down
            // it won't stop users from booking.
            const o = [
                customOrg,
            ];
            setOptions(o);
            return;
        }
        const json = yield res.json();
        const result = json;
        const o = [
            customOrg,
            ...result.result.records
        ];
        setOptions(o);
    });
    return _jsx(Autocomplete, { size: "small", disablePortal: true, options: options, value: inputValue || null, filterOptions: (x) => x, onChange: handleOrgSelect, onInputChange: handleInputChange, renderInput: (params) => _jsx(TextField, Object.assign({ variant: "outlined" }, params)), getOptionLabel: (option) => option["Current Name"] || option["Company Name"], sx: {
            'input': {
                border: 0,
                backgroundColor: 'rgba(0,0,0,0)',
                fontSize: 14
            }
        } });
}
