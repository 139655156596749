var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import apiFetch from '@wordpress/api-fetch';
import { addQueryArgs } from '@wordpress/url';
import { useContext, useEffect, useState } from 'react';
import { CourseContext } from '../CourseContextProvider';
import { EnrolmentContext } from '../EnrolmentContextProvider';
import { LinearProgress, Typography } from '@mui/material';
import { ErrorContext } from '../ErrorContextProvider';
// Keep this outside the render function so it wont be recreated on re-render
const stripePromise = loadStripe(window.ati_ax_globals.enrolment.stripePK);
export default function PaymentStep(props) {
    const [clientSecret, setClientSecret] = useState('');
    const { setError } = useContext(ErrorContext);
    const { courseName, courseCost, courseDate } = useContext(CourseContext);
    const { emailAddress, firstName, lastName, attendees, setStripePaymentIntentID } = useContext(EnrolmentContext);
    const options = {
        clientSecret: clientSecret,
        appearance: {
            theme: 'stripe',
            labels: 'above',
        },
    };
    useEffect(() => {
        apiFetch({
            path: addQueryArgs(window.ati_ax_globals.enrolment.stripeSecretURL, {}),
            method: 'POST',
            data: {
                contactName: `${firstName} ${lastName}`,
                contactEmail: emailAddress,
                courseName: courseName,
                courseDate: courseDate,
                courseCost: courseCost,
                attendeeCount: attendees.length,
            },
        }).then((res) => {
            const payment_intent = JSON.parse(res.body);
            setStripePaymentIntentID(payment_intent.id);
            setClientSecret(payment_intent.client_secret);
        })
            .catch((e) => {
            setError(new Error(e.message));
        });
    }, []);
    return _jsx(_Fragment, { children: clientSecret != ''
            &&
                _jsx(Elements, { stripe: stripePromise, options: options, children: _jsx(PaymentForm, { formRef: props.formRef }) })
            ||
                _jsx(LinearProgress, {}) });
}
;
const PaymentForm = (props) => {
    const { setError } = useContext(ErrorContext);
    const { setPurchasing, emailAddress } = useContext(EnrolmentContext);
    const { courseID, instanceID, activityType } = useContext(CourseContext);
    const stripe = useStripe();
    const elements = useElements();
    const handleSubmit = (event) => __awaiter(void 0, void 0, void 0, function* () {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        setPurchasing(true);
        const redirectURL = new URL(window.ati_ax_globals.enrolment.stripeSuccessRedirect);
        redirectURL.searchParams.append('course_id', courseID.toString());
        redirectURL.searchParams.append('instance_id', instanceID.toString());
        redirectURL.searchParams.append('course_type', activityType);
        const { error } = yield stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: redirectURL.href,
            },
        });
        if (error) {
            // Show error to your customer (for example, payment details incomplete)
            setPurchasing(false);
            setError(new Error(error.message));
        }
        else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    });
    return (_jsxs("form", { ref: props.formRef, onSubmit: handleSubmit, children: [_jsx(PaymentElement, { options: {
                    fields: {
                        billingDetails: {
                            email: 'auto',
                            name: 'auto'
                        }
                    },
                    wallets: {
                        applePay: 'auto',
                        googlePay: 'auto'
                    }
                } }), _jsxs(Typography, { variant: 'body1', mt: "1em", children: ["By placing your order, you agree to our ", _jsx("a", { href: "/terms-and-conditions/", target: "_blank", children: "Terms of Service" }), " and ", _jsx("a", { href: "/privacy-policy/", target: "_blank", children: "Privacy Policy" }), "."] })] }));
};
