var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import ContactDetailsStep from './components/ContactDetailsStep';
import EnrolmentStep from './components/EnrolmentStep';
import { CourseContext } from './CourseContextProvider';
import ReviewStep from './components/ReviewStep';
import PaymentStep from './components/PaymentStep';
import apiFetch from '@wordpress/api-fetch';
import { addQueryArgs } from '@wordpress/url';
import { ErrorContext } from './ErrorContextProvider';
import { checkEmail } from '../utils';
export var EnrolmentBookingType;
(function (EnrolmentBookingType) {
    EnrolmentBookingType["single"] = "single";
    EnrolmentBookingType["group"] = "group";
})(EnrolmentBookingType || (EnrolmentBookingType = {}));
export const EnrolmentContext = createContext(null);
export const EnrolmentProvider = ({ children }) => {
    const { setError } = useContext(ErrorContext);
    const { courseCost, courseID, instanceID, activityType } = useContext(CourseContext);
    // Is the currently active user the payer
    const [isPayer, setIsPayer] = useState(true);
    const singleSteps = ['Contact Details', 'Review', 'Purchase'];
    const groupSteps = ['Contact Details', 'Enrolment', 'Review', 'Purchase'];
    const [bookingType, setBookingType] = useState(EnrolmentBookingType.single);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [organisation, setOrganisation] = useState("");
    const [ABN, setABN] = useState(0);
    const [studyReason, setStudyReason] = useState("");
    const [reference, setReference] = useState("");
    const [isContactAttending, setIsContactAttending] = useState(true);
    const [attendees, setAttendees] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [canCompleteActiveStep, setCanCompleteActiveStep] = useState(false);
    const [stepFragment, setStepFragment] = useState(_jsx(_Fragment, {}));
    const [enrolmentCost, setEnrolmentCost] = useState(courseCost);
    const [receiveMarketing, setReceiveMarketing] = useState(true);
    const [acknowledgeHandbook, setAcknowledgeHandbook] = useState(false);
    const [purchasing, setPurchasing] = useState(false);
    const purchaseFormRef = useRef(null);
    const [stripePaymentIntentID, setStripePaymentIntentID] = useState("");
    const [enrolmentCompleted, setEnrolmentCompleted] = useState(false);
    const [updateContactFormCompleted, setUpdateContactFormCompleted] = useState(false);
    const [enrolmentFinalised, setEnrolmentFinalised] = useState(false);
    const [steps, setSteps] = useState(['Contact Details', 'Review', 'Purchase']);
    const nextStep = () => {
        if (activeStep == steps.length - 1) {
            if (purchaseFormRef.current) {
                purchaseFormRef.current.requestSubmit();
            }
        }
        else {
            setActiveStep(activeStep + 1);
        }
    };
    const prevStep = () => {
        setActiveStep(activeStep - 1);
    };
    const createTentativeEnrolment = () => {
        apiFetch({
            path: window.ati_ax_globals.enrolment.axTentativeEnrolmentAction,
            method: 'POST',
            data: {
                'contact': {
                    firstName,
                    lastName,
                    emailAddress,
                    phoneNumber,
                    organisation
                },
                'attendees': attendees,
                'bookingType': bookingType,
                'instanceID': instanceID,
                'activityType': activityType
            }
        })
            .catch((e) => { setError(new Error(e.message)); });
    };
    // Check for payment_intent query param, if it exists then we are in the 'post-enrolment' phase
    // so load the enrolment details from the database and populate the context provider
    useEffect(() => {
        const searchParams = new URLSearchParams(document.location.search);
        const paymentIntent = searchParams.get("payment_intent") || "";
        const token = searchParams.get("token") || "";
        if (token)
            setIsPayer(false);
        // Only load the enrolment data from the database if the payment_intent query param is present
        if (!paymentIntent && !token)
            return;
        const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
            const res = yield apiFetch({
                path: addQueryArgs(window.ati_ax_globals.postEnrolment.loadAction, {
                    paymentIntent: paymentIntent,
                    token: token
                })
            })
                .catch((e) => {
                setError(new Error(e.message));
            });
            const data = res;
            if (data.bookingType == EnrolmentBookingType.single)
                setActiveStep(singleSteps.length);
            else if (data.bookingType == EnrolmentBookingType.group)
                setActiveStep(groupSteps.length);
            setBookingType(data.bookingType);
            setFirstName(data.firstName);
            setLastName(data.lastName);
            setEmailAddress(data.emailAddress);
            setPhoneNumber(data.phoneNumber);
            setOrganisation(data.organisation);
            setABN(parseInt(data.ABN));
            setReference(data.reference);
            setAttendees(data.attendees);
            setReceiveMarketing(data.marketing == "1");
            setEnrolmentCompleted(data.completed == "1");
            setAcknowledgeHandbook(true);
        });
        fetchData();
    }, []);
    // When the stripe payment intent id changes we need to save the current enrolment
    // into the database so that when the user is redirected after payment we can process it further.
    useEffect(() => {
        if (!stripePaymentIntentID)
            return;
        apiFetch({
            path: window.ati_ax_globals.enrolment.saveAction,
            method: 'POST',
            data: {
                "stripePaymentIntentId": stripePaymentIntentID,
                "axCourseID": courseID,
                "axInstanceID": instanceID,
                "axActivityType": activityType,
                "bookingType": bookingType,
                "contact": {
                    "firstName": firstName,
                    "lastName": lastName,
                    "emailAddress": emailAddress,
                    "phoneNumber": phoneNumber,
                    "organisation": organisation,
                    "ABN": ABN,
                    "reference": reference,
                    "marketing": receiveMarketing,
                },
                "attendees": attendees,
            }
        })
            .catch((e) => {
            setError(new Error(e.message));
        });
    }, [stripePaymentIntentID]);
    // TODO: This will need to change once discounts or course items (eg printouts) are implemented
    useEffect(() => {
        if (attendees.length > 1) {
            setEnrolmentCost(courseCost * attendees.length);
        }
        else {
            setEnrolmentCost(courseCost);
        }
    }, [courseCost, attendees]);
    useEffect(() => {
        if (bookingType == EnrolmentBookingType.single) {
            if (firstName && lastName && emailAddress && studyReason && phoneNumber) {
                setAttendees([{
                        firstName,
                        lastName,
                        emailAddress,
                        studyReason,
                        phoneNumber,
                        token: ''
                    }]);
            }
        }
        else {
            setAttendees([]);
        }
    }, [firstName, lastName, emailAddress, phoneNumber, studyReason, bookingType]);
    // Calculate if the user can progress past the current step
    useEffect(() => {
        if (bookingType == EnrolmentBookingType.single) {
            switch (activeStep) {
                case 0:
                    let completable = true;
                    if (firstName == '')
                        completable = false;
                    else if (lastName == '')
                        completable = false;
                    else if (emailAddress == '')
                        completable = false;
                    else if (phoneNumber == '')
                        completable = false;
                    else if (reference == '')
                        completable = false;
                    else if (studyReason == '')
                        completable = false;
                    if (!checkEmail(emailAddress))
                        completable = false;
                    setCanCompleteActiveStep(completable);
                    break;
                case 1: {
                    setCanCompleteActiveStep(acknowledgeHandbook);
                    break;
                }
                case 2: {
                    setCanCompleteActiveStep(!purchasing);
                }
            }
        }
        else if (bookingType == EnrolmentBookingType.group) {
            switch (activeStep) {
                case 0:
                    let completable = true;
                    if (firstName == '')
                        completable = false;
                    else if (lastName == '')
                        completable = false;
                    else if (emailAddress == '')
                        completable = false;
                    else if (phoneNumber == '')
                        completable = false;
                    else if (reference == '')
                        completable = false;
                    if (!checkEmail(emailAddress))
                        completable = false;
                    setCanCompleteActiveStep(completable);
                    break;
                case 1:
                    setCanCompleteActiveStep(attendees.length >= 1);
                    break;
                case 2: {
                    setCanCompleteActiveStep(acknowledgeHandbook);
                    break;
                }
                case 3: {
                    setCanCompleteActiveStep(!purchasing);
                }
            }
        }
    }, [firstName, lastName, emailAddress, phoneNumber, studyReason, bookingType, activeStep, reference, attendees, acknowledgeHandbook]);
    // Update which fragment should be shown when the activeStep changes
    useEffect(() => {
        const scroll = () => {
            var _a, _b;
            (_a = document.getElementById('desktop-stepper')) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ block: 'center' });
            (_b = document.getElementById('mobile-stepper')) === null || _b === void 0 ? void 0 : _b.scrollIntoView({ block: 'start' });
        };
        if (bookingType === EnrolmentBookingType.single) {
            switch (activeStep) {
                case 0:
                    scroll();
                    setStepFragment(_jsx(ContactDetailsStep, {}));
                    break;
                case 1:
                    scroll();
                    setStepFragment(_jsx(ReviewStep, {}));
                    break;
                case 2:
                    scroll();
                    createTentativeEnrolment();
                    setStepFragment(_jsx(PaymentStep, { formRef: purchaseFormRef }));
                    break;
            }
        }
        else if (bookingType === EnrolmentBookingType.group) {
            switch (activeStep) {
                case 0:
                    scroll();
                    setStepFragment(_jsx(ContactDetailsStep, {}));
                    break;
                case 1:
                    scroll();
                    setStepFragment(_jsx(EnrolmentStep, {}));
                    break;
                case 2:
                    scroll();
                    setStepFragment(_jsx(ReviewStep, {}));
                    break;
                case 3:
                    scroll();
                    createTentativeEnrolment();
                    setStepFragment(_jsx(PaymentStep, { formRef: purchaseFormRef }));
                    break;
            }
        }
    }, [activeStep, bookingType]);
    // Single bookings the contact _MUST_ attend, if they do not wish to attend they must make a group booking.
    useEffect(() => {
        setIsContactAttending(bookingType === EnrolmentBookingType.single);
        if (bookingType === EnrolmentBookingType.single)
            setSteps(singleSteps);
        else if (bookingType === EnrolmentBookingType.group)
            setSteps(groupSteps);
    }, [bookingType]);
    return (_jsx(EnrolmentContext.Provider, { value: {
            bookingType,
            setBookingType,
            firstName,
            setFirstName,
            lastName,
            setLastName,
            emailAddress,
            setEmailAddress,
            phoneNumber,
            setPhoneNumber,
            organisation,
            setOrganisation,
            ABN,
            setABN,
            studyReason,
            setStudyReason,
            reference,
            setReference,
            isContactAttending,
            setIsContactAttending,
            attendees,
            setAttendees,
            steps,
            enrolmentCost,
            setEnrolmentCost,
            activeStep,
            setActiveStep,
            canCompleteActiveStep,
            stepFragment,
            receiveMarketing,
            setReceiveMarketing,
            acknowledgeHandbook,
            setAcknowledgeHandbook,
            nextStep,
            prevStep,
            purchasing,
            setPurchasing,
            stripePaymentIntentID,
            setStripePaymentIntentID,
            enrolmentCompleted,
            setEnrolmentCompleted,
            updateContactFormCompleted,
            setUpdateContactFormCompleted,
            isPayer,
            setIsPayer,
            enrolmentFinalised,
            setEnrolmentFinalised
        }, children: children }));
};
