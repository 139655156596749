import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import { EnrolmentBookingType, EnrolmentContext } from '../EnrolmentContextProvider';
import { Checkbox, FormControlLabel, styled, Table, TableCell, TableHead, TableRow } from '@mui/material';
const FormGrid = styled(Grid)(() => ({
    display: 'flex',
    flexDirection: 'column',
}));
export default function ReviewStep() {
    const { bookingType, attendees, firstName, lastName, emailAddress, phoneNumber, organisation, ABN, acknowledgeHandbook, setAcknowledgeHandbook, receiveMarketing, setReceiveMarketing } = useContext(EnrolmentContext);
    const marketingSingle = "Stay updated about our courses, events, and promotions.";
    const marketingGroup = "I confirm that I have the consent of all group members to stay updated about our courses, events, and promotions.";
    const contactInformation = [
        {
            name: 'Name',
            desc: `${firstName} ${lastName}`
        },
        {
            name: 'Email Address',
            desc: emailAddress
        },
        {
            name: 'Phone Number',
            desc: phoneNumber
        },
    ];
    if (organisation) {
        contactInformation.push({
            name: 'Organisation',
            desc: organisation
        });
    }
    if (ABN) {
        contactInformation.push({
            name: 'ABN',
            desc: ABN.toString()
        });
    }
    return (_jsxs(Stack, { direction: "column", spacing: 1, sx: {
            'table': {
                'border': '0 !important'
            },
            'th': {
                'paddingBottom': '0 !important'
            },
            'td': {
                'border': '0 !important',
                'paddingTop': '0 !important',
                'paddingBottom': '0 !important'
            }
        }, children: [_jsxs(Table, { size: 'small', children: [_jsx(TableHead, { children: _jsx(TableRow, { children: _jsx(TableCell, { colSpan: 2, children: _jsx(Typography, { variant: "subtitle1", pb: 0, children: "Contact Information" }) }) }) }), _jsx(TableRow, { children: _jsx(TableCell, { colSpan: 2, children: "\u00A0" }) }), " ", contactInformation.map((i) => {
                        return _jsxs(TableRow, { children: [_jsx(TableCell, { size: 'small', children: _jsxs(Typography, { variant: "body1", sx: { color: 'text.secondary' }, pb: 0, children: [i.name, ":"] }) }), _jsx(TableCell, { size: 'small', children: _jsx(Typography, { variant: "body1", pb: 0, children: i.desc }) })] }, i.name);
                    }), bookingType === EnrolmentBookingType.group &&
                        _jsxs(_Fragment, { children: [_jsx(TableRow, { children: _jsx(TableCell, { colSpan: 2, children: "\u00A0" }) }), " ", _jsx(TableHead, { children: _jsx(TableRow, { children: _jsx(TableCell, { colSpan: 2, children: _jsx(Typography, { variant: "subtitle1", pb: 0, children: "Attendees" }) }) }) }), _jsx(TableRow, { children: _jsx(TableCell, { colSpan: 2, children: "\u00A0" }) }), " ", attendees.map((a) => (_jsxs(TableRow, { children: [_jsx(TableCell, { size: 'small', children: _jsxs(Typography, { variant: "body1", sx: { color: 'text.secondary' }, pb: 0, children: [a.firstName, " ", a.lastName, ":"] }) }), _jsx(TableCell, { size: 'small', children: _jsx(Typography, { variant: "body1", children: a.emailAddress }) })] }, a.emailAddress)))] }), _jsx(TableRow, { children: _jsx(TableCell, { colSpan: 2, children: "\u00A0" }) }), " "] }), _jsxs("div", { children: [_jsx(FormGrid, { size: { xs: 12 }, children: _jsx(FormControlLabel, { control: _jsx(Checkbox, { name: "receiveMarketing", checked: receiveMarketing, value: receiveMarketing, onChange: (_, c) => setReceiveMarketing(c) }), label: bookingType === EnrolmentBookingType.single ? marketingSingle : marketingGroup }) }), _jsx(FormGrid, { size: { xs: 12 }, sx: { paddingTop: '8px' }, children: _jsx(FormControlLabel, { sx: {
                                alignItems: 'start',
                                '.MuiCheckbox-root': {
                                    paddingTop: '0 !important'
                                }
                            }, control: _jsx(Checkbox, { name: "acknowledgeHandbook", checked: acknowledgeHandbook, value: acknowledgeHandbook, onChange: (_, c) => setAcknowledgeHandbook(c) }), label: _jsxs(_Fragment, { children: ["I have read and understood the course information provided to me via the website, flyers, promotions or other means. I also acknowledge that I have read and understood the ", _jsx("a", { href: "https://ati.app.axcelerate.com/showDoc.cfm?DocID=467337", children: "Student Handbook" }), ", including the refund policy."] }) }) })] })] }));
}
