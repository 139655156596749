import { jsx as _jsx } from "react/jsx-runtime";
import { MenuItem, OutlinedInput, Select } from "@mui/material";
export default function AVETMISSLanguageSelector(props) {
    return _jsx(Select, { id: props.name, name: props.name, input: _jsx(OutlinedInput, {}), required: props.required, disabled: props.disabled, value: props.value, onChange: (e) => props.setValue(e.target.value), MenuProps: { PaperProps: { sx: { maxHeight: '75vh' } } }, size: "small", error: props.error, children: Object.keys(ncverList)
            .sort((a, b) => (ncverList[a] > ncverList[b] ? 1 : 0))
            .map((key) => _jsx(MenuItem, { value: key, children: ncverList[key] }, key)) });
}
const ncverList = {
    "8998": "Aboriginal English",
    "6513": "Acehnese",
    "9201": "Acholi",
    "8901": "Adnymathanha",
    "9299": "African Languages,(other)",
    "1403": "Afrikaans",
    "9203": "Akan",
    "8121": "Alawa",
    "3901": "Albanian",
    "8315": "Alngith",
    "8603": "Alyawarr",
    "9101": "American Languages",
    "9214": "Amharic",
    "8156": "Amurdak",
    "8101": "Anindilyakwa",
    "8619": "Anmatyerr,(other)",
    "8607": "Antekerrepenh",
    "8703": "Antikarinya",
    "9241": "Anuak",
    "8902": "Arabana",
    "4202": "Arabic",
    "8699": "Arandic,(other)",
    "4901": "Armenian",
    "8199": "Arnhem Land and Daly River Region Languages,(other)",
    "3903": "Aromunian (Macedo-Romanian)",
    "8629": "Arrernte,(other)",
    "5213": "Assamese",
    "4206": "Assyrian Neo-Aramaic",
    "9701": "Auslan",
    "4302": "Azeri",
    "8946": "Baanbay",
    "8947": "Badimaya",
    "6514": "Balinese",
    "4104": "Balochi",
    "8903": "Bandjalang",
    "8904": "Banyjima",
    "8948": "Barababaraba",
    "8801": "Bardi",
    "9242": "Bari",
    "2901": "Basque",
    "9243": "Bassa",
    "8905": "Batjala",
    "3401": "Belorussian",
    "9215": "Bemba",
    "5201": "Bengali",
    "8906": "Bidjara",
    "6515": "Bikol",
    "8504": "Bilinarra",
    "6501": "Bisaya",
    "9402": "Bislama",
    "3501": "Bosnian",
    "3502": "Bulgarian",
    "8802": "Bunuba",
    "8181": "Burarra",
    "8189": "Burarran,(other)",
    "6101": "Burmese",
    "6199": "Burmese and Related Languages,(other)",
    "7101": "Cantonese",
    "8399": "Cape York Peninsula Languages,(other)",
    "2301": "Catalan",
    "6502": "Cebuano",
    "1199": "Celtic,(other)",
    "8611": "Central Anmatyerr",
    "4207": "Chaldean Neo-Aramaic",
    "6102": "Chin Haka",
    "7199": "Chinese,(other)",
    "3503": "Croatian",
    "3601": "Czech",
    "3604": "Czechoslovakian",
    "8233": "Daatiwuy",
    "8951": "Dadi Dadi",
    "8122": "Dalabon",
    "9244": "Dan (Gio-Dan)",
    "1501": "Danish",
    "4105": "Dari",
    "8221": "Dhalwangu",
    "8907": "Dhanggatti",
    "8219": "Dhangu,(other)",
    "8952": "Dharawal",
    "8229": "Dhay'yi,(other)",
    "5214": "Dhivehi",
    "8239": "Dhuwal,(other)",
    "8249": "Dhuwala,(other)",
    "8291": "Dhuwaya",
    "9216": "Dinka",
    "8908": "Diyari",
    "8305": "Djabugay",
    "8953": "Djabwurrung",
    "8231": "Djambarrpuyngu",
    "8292": "Djangu",
    "8232": "Djapu",
    "8222": "Djarrwark",
    "8259": "Djinang,(other)",
    "8262": "Djinba",
    "8269": "Djinba,(other)",
    "5199": "Dravidian,(other)",
    "1401": "Dutch",
    "8306": "Dyirbal",
    "8612": "Eastern Anmatyerr",
    "8621": "Eastern Arrernte",
    "1201": "English",
    "1601": "Estonian",
    "9217": "Ewe",
    "9301": "Fijian",
    "5217": "Fijian Hindustani",
    "6512": "Filipino",
    "1602": "Finnish",
    "1699": "Finnish and Related Languages,(other)",
    "2101": "French",
    "1402": "Frisian",
    "9245": "Fulfulde",
    "9218": "Ga",
    "1101": "Gaelic (Scotland)",
    "8211": "Galpu",
    "8813": "Gambera",
    "8911": "Gamilaraay",
    "8261": "Ganalbingu",
    "8157": "Garrwa",
    "8913": "Garuwali",
    "4902": "Georgian",
    "1301": "German",
    "9302": "Gilbertese",
    "8307": "Girramay",
    "8914": "Githabul",
    "8212": "Golumala",
    "8803": "Gooniyandi",
    "2201": "Greek",
    "8123": "Gudanji",
    "8954": "Gudjal",
    "5202": "Gujarati",
    "8242": "Gumatj",
    "8915": "Gumbaynggir",
    "8182": "Gun-nartpa",
    "8171": "Gundjeihmi",
    "8243": "Gupapuyngu",
    "8505": "Gurindji",
    "8506": "Gurindji Kriol",
    "8183": "Gurr-goni",
    "8302": "Guugu Yimidhirr",
    "8244": "Guyamirrilili",
    "7102": "Hakka",
    "9221": "Harari",
    "9222": "Hausa",
    "9403": "Hawaiian English",
    "4107": "Hazaraghi",
    "4204": "Hebrew",
    "5203": "Hindi",
    "6201": "Hmong",
    "6299": "Hmong-Mien,(other)",
    "3301": "Hungarian",
    "6516": "Iban",
    "2399": "Iberian Romance,(other)",
    "1502": "Icelandic",
    "9223": "Igbo",
    "6503": "IIokano",
    "6517": "Ilonggo (Hiligaynon)",
    "5299": "Indo-Aryan,(other)",
    "6504": "Indonesian",
    "9601": "Invented Languages",
    "4199": "Iranic,(other)",
    "1102": "Irish",
    "2401": "Italian",
    "8127": "Iwaidja",
    "8128": "Jaminjung",
    "7201": "Japanese",
    "8507": "Jaru",
    "6518": "Javanese",
    "8814": "Jawi",
    "8131": "Jawoyn",
    "8132": "Jingulu",
    "8401": "Kalaw Kawaw Ya/Kalaw Lagaw Ya",
    "8916": "Kanai",
    "5101": "Kannada",
    "8917": "Karajarri",
    "6103": "Karen",
    "8918": "Kariyarra",
    "8704": "Kartujarra",
    "5215": "Kashmiri",
    "8921": "Kaurna",
    "8922": "Kayardild",
    "8606": "Kaytetye",
    "8955": "Keerray-Woorroong",
    "9702": "Key Word Sign Australia",
    "6301": "Khmer",
    "8815": "Kija",
    "9224": "Kikuyu",
    "8899": "Kimberley Area Languages,(other)",
    "9246": "Kinyarwanda (Rwanda)",
    "9247": "Kirundi (Rundi)",
    "9502": "Kiwai",
    "8308": "Koko-Bera",
    "5204": "Konkani",
    "7301": "Korean",
    "9248": "Kpelle",
    "9251": "Krahn",
    "9225": "Krio",
    "8924": "Kriol",
    "8316": "Kugu Muminh",
    "8705": "Kukatha",
    "8706": "Kukatja",
    "8301": "Kuku Yalanji",
    "8133": "Kunbarlang",
    "8172": "Kune",
    "8173": "Kuninjku",
    "8174": "Kunwinjku",
    "8179": "Kunwinjkuan,(other)",
    "4101": "Kurdish",
    "8311": "Kuuk Thayorre",
    "8303": "Kuuku-Ya'u",
    "8158": "Kuwema",
    "8956": "Ladji Ladji",
    "8312": "Lamalama",
    "6401": "Lao",
    "8925": "Lardil",
    "8136": "Larrakiya",
    "2902": "Latin",
    "3101": "Latvian",
    "1302": "Letzeburgish",
    "9252": "Liberian (Liberian English)",
    "8508": "Light Warlpiri",
    "9262": "Lingala",
    "3102": "Lithuanian",
    "8235": "Liyagalawumirr",
    "8236": "Liyagawumirr",
    "9253": "Loma (Lorma)",
    "9226": "Luganda",
    "9254": "Lumun (Kuku Lumun)",
    "9227": "Luo",
    "8707": "Luritja",
    "3504": "Macedonian",
    "8293": "Madarrpa",
    "9255": "Madi",
    "8137": "Malak Malak",
    "6505": "Malay",
    "5102": "Malayalam",
    "8511": "Malngin",
    "2501": "Maltese",
    "4208": "Mandaean (Mandaic)",
    "7104": "Mandarin",
    "9256": "Mandinka",
    "8926": "Mangala",
    "8138": "Mangarrayi",
    "8246": "Manggalili",
    "9257": "Mann",
    "8263": "Manyjalpingu",
    "8708": "Manyjilyjarra",
    "9303": "Maori (Cook Island)",
    "9304": "Maori (New Zealand)",
    "5205": "Marathi",
    "8141": "Maringarr",
    "8142": "Marra",
    "8161": "Marramaninyshi",
    "8234": "Marrangu",
    "8166": "Marridan (Maridan)",
    "8143": "Marrithiyel",
    "8711": "Martu Wangka",
    "8144": "Matngala",
    "8111": "Maung",
    "9205": "Mauritian Creole",
    "8175": "Mayali",
    "8402": "Meriam Mir",
    "4299": "Middle Eastern Semitic Languages,(other)",
    "7107": "Min Nan",
    "8804": "Miriwoong",
    "8957": "Mirning",
    "6303": "Mon",
    "6399": "Mon-Khmer,(other)",
    "7902": "Mongolian",
    "9258": "Moro (Nuba Moro)",
    "8317": "Morrobalama",
    "9503": "Motu (HiriMotu)",
    "8512": "Mudburra",
    "8146": "Murrinh Patha",
    "8927": "Muruwari",
    "8147": "Na-kara",
    "8928": "Narungga",
    "9306": "Nauruan",
    "9228": "Ndebele",
    "8148": "Ndjebbana (Gunavidji)",
    "5206": "Nepali",
    "8712": "Ngaanyatjarra",
    "8151": "Ngalakgan",
    "8152": "Ngaliwurru",
    "8113": "Ngan'gikurunggurr",
    "8162": "Ngandi",
    "8514": "Ngardi",
    "8805": "Ngarinyin",
    "8515": "Ngarinyman",
    "8931": "Ngarluma",
    "8932": "Ngarrindjeri",
    "8958": "Ngatjumaya",
    "8281": "Nhangu",
    "8289": "Nhangu,(other)",
    "9307": "Niue",
    "9404": "Norf'k-Pitcairn",
    "8599": "Northern Desert Fringe Area Languages,(other)",
    "1503": "Norwegian",
    "9231": "Nuer",
    "8153": "Nungali",
    "8114": "Nunggubuyu",
    "8933": "Nyamal",
    "8934": "Nyangumarta",
    "9232": "Nyanja (Chichewa)",
    "8806": "Nyikina",
    "8935": "Nyungar",
    "9499": "Oceanian Pidgins and Creoles,(other)",
    "5216": "Oriya",
    "9206": "Oromo",
    "8999": "Other Australian Indigenous Languages,(other)",
    "7999": "Other Eastern Asian Languages,(other)",
    "3999": "Other Eastern European Languages,(other)",
    "6999": "Other Southeast Asian Languages",
    "5999": "Other Southern Asian Languages",
    "2999": "Other Southern European Languages,(other)",
    "4999": "Other Southwest and Central Asian Languages,(other)",
    "8299": "Other Yolngu Matha,(other)",
    "8936": "Paakantyi",
    "9399": "Pacific Austronesian Languages,(other)",
    "8937": "Palyku/Nyiyaparli",
    "6521": "Pampangan",
    "9599": "Papua New Guinea Languages,(other)",
    "4102": "Pashto",
    "4106": "Persian (excluding Dari)",
    "8713": "Pintupi",
    "8714": "Pitjantjatjara",
    "3602": "Polish",
    "2302": "Portuguese",
    "5207": "Punjabi",
    "8115": "Rembarrnga",
    "8295": "Rirratjingu",
    "8271": "Ritharrngu",
    "6104": "Rohingya",
    "3904": "Romanian",
    "3905": "Romany",
    "9312": "Rotuman",
    "3402": "Russian",
    "9308": "Samoan",
    "1599": "Scandinavian,(other)",
    "3505": "Serbian",
    "3507": "Serbo-Croatian/Yugoslavian",
    "9238": "Seychelles Creole",
    "9233": "Shilluk",
    "9207": "Shona",
    "9799": "Sign Languages,(other)",
    "5208": "Sindhi",
    "5211": "Sinhalese",
    "3603": "Slovak",
    "3506": "Slovene",
    "9405": "Solomon Islands Pijin",
    "9208": "Somali",
    "6599": "Southeast Asian Austronesian Languages,(other)",
    "2303": "Spanish",
    "9211": "Swahili",
    "1504": "Swedish",
    "6511": "Tagalog",
    "6499": "Tai,(other)",
    "5103": "Tamil",
    "4303": "Tatar",
    "5104": "Telugu",
    "6507": "Tetum",
    "6402": "Thai",
    "8318": "Thaynakwith",
    "9261": "Themne",
    "7901": "Tibetan",
    "9234": "Tigre",
    "9235": "Tigrinya",
    "6508": "Timorese",
    "8117": "Tiwi",
    "8322": "Tjungundji",
    "8722": "Tjupany",
    "9504": "Tok Pisin (Neomelanesian)",
    "9313": "Tokelauan",
    "9311": "Tongan",
    "9236": "Tswana",
    "5105": "Tulu",
    "4399": "Turkic,(other)",
    "4301": "Turkish",
    "4304": "Turkmen",
    "9314": "Tuvaluan",
    "3403": "Ukrainian",
    "5212": "Urdu",
    "4305": "Uygur",
    "4306": "Uzbek",
    "6302": "Vietnamese",
    "8163": "Waanyi",
    "8272": "Wagilak",
    "8164": "Wagiman",
    "8938": "Wajarri",
    "8516": "Walmajarri",
    "8961": "Waluwarra",
    "8154": "Wambaya",
    "8715": "Wangkajunga",
    "8962": "Wangkangurru",
    "8716": "Wangkatha",
    "8213": "Wangurri",
    "8517": "Wanyjirra",
    "8155": "Wardaman",
    "8963": "Wargamay",
    "8518": "Warlmanpa",
    "8521": "Warlpiri",
    "8717": "Warnman",
    "8294": "Warramiri",
    "8522": "Warumungu",
    "1103": "Welsh",
    "8964": "Wergaia",
    "8622": "Western Arrarnta",
    "8799": "Western Desert Languages,(other)",
    "8304": "Wik Mungkan",
    "8314": "Wik Ngathan",
    "8941": "Wiradjuri",
    "8807": "Worla",
    "8808": "Worrorra",
    "7106": "Wu",
    "8247": "Wubulkarra",
    "8811": "Wunambal",
    "8251": "Wurlaki",
    "9237": "Xhosa",
    "8279": "Yakuy,(other)",
    "8282": "Yan-nhangu",
    "8718": "Yankunytjatjara",
    "8165": "Yanyuwa",
    "9315": "Yapese",
    "8812": "Yawuru",
    "1303": "Yiddish",
    "8313": "Yidiny",
    "8943": "Yindjibarndi",
    "8944": "Yinhawangka",
    "8945": "Yorta Yorta",
    "9212": "Yoruba",
    "8965": "Yugambeh",
    "8721": "Yulparija",
    "8403": "Yumplatok (Torres Strait Creole)",
    "8321": "Yupangathi",
    "6105": "Zomi",
    "9213": "Zulu"
};
