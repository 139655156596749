import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// Inspired by: https://github.com/mui/material-ui/tree/v6.4.6/docs/data/material/getting-started/templates/checkout
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid2';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import Info from './components/Info';
import InfoMobile from './components/InfoMobile';
import AppTheme from '../shared-theme/AppTheme';
import { CourseContext } from '../enrolment/CourseContextProvider';
import { useContext } from 'react';
import { Alert, LinearProgress } from '@mui/material';
import { EnrolmentContext } from './EnrolmentContextProvider';
import PostEnrolment from './PostEnrolment';
export default function Enrolment(props) {
    const { courseLoading, courseLoadingError, courseLoadingErrorMsg } = useContext(CourseContext);
    const { enrolmentCost, canCompleteActiveStep, activeStep, steps, nextStep, prevStep, stepFragment, purchasing, isPayer, enrolmentFinalised } = useContext(EnrolmentContext);
    return (_jsxs(_Fragment, { children: [_jsx("div", { hidden: !courseLoading, children: _jsx(Box, { sx: { width: '100%' }, children: _jsx(LinearProgress, {}) }) }), _jsx(Alert, { hidden: !courseLoadingError, severity: 'error', children: courseLoadingErrorMsg }), !courseLoading && !courseLoadingError &&
                _jsxs(AppTheme, Object.assign({ disableCustomTheme: true }, props, { children: [_jsx(CssBaseline, { enableColorScheme: true }), _jsxs(Grid, { container: true, sx: {
                                mt: {
                                    xs: 0,
                                    sm: 0,
                                },
                            }, children: [_jsx(Grid, { size: { sm: 12, md: 12, lg: 4 }, sx: {
                                        display: { xs: 'none', md: 'flex' },
                                        flexDirection: 'column',
                                        backgroundColor: 'background.paper',
                                        borderRight: { sm: 'none', md: '1px solid' },
                                        borderColor: { sm: 'none', md: 'divider' },
                                        alignItems: 'start',
                                        pt: 4,
                                        px: 10,
                                        gap: 4,
                                    }, children: _jsx(Box, { sx: {
                                            display: 'flex',
                                            flexDirection: 'column',
                                            flexGrow: 1,
                                            width: '100%',
                                            // maxWidth: 500,
                                        }, children: _jsx(Info, {}) }) }), _jsxs(Grid, { size: { sm: 12, md: 12, lg: 8 }, sx: {
                                        display: 'flex',
                                        flexDirection: 'column',
                                        maxWidth: '100%',
                                        width: '100%',
                                        backgroundColor: { xs: 'transparent', sm: 'background.default' },
                                        alignItems: 'start',
                                        pt: { xs: 0, sm: 4 },
                                        px: { xs: 2, sm: 10 },
                                        gap: { xs: 4, md: 4 },
                                    }, children: [_jsxs(Box, { sx: {
                                                alignItems: 'center',
                                                width: '100%',
                                                maxWidth: { sm: '100%', md: '100%' },
                                            }, children: [_jsxs(Typography, { variant: 'h5', children: [activeStep != steps.length && 'Course Booking Form' || 'Enrolment Details', " "] }), activeStep == steps.length
                                                    &&
                                                        _jsxs(_Fragment, { children: [_jsx(Typography, { variant: 'body2', hidden: !enrolmentFinalised, children: "Please complete the following enrolment details to help us collect accurate student information as required under the National VET Data Policy. Returning students must also complete this form to confirm current details." }), _jsx(Typography, { variant: 'body2', hidden: !enrolmentFinalised, children: "This ensures your training records are up to date and allows us to meet government reporting requirements." })] })] }), _jsx(Box, { sx: {
                                                display: 'flex',
                                                justifyContent: { sm: 'space-between', md: 'flex-end' },
                                                alignItems: 'center',
                                                width: '100%',
                                                maxWidth: { sm: '100%', md: '100%' },
                                            }, children: _jsx(Box, { sx: {
                                                    display: { xs: 'none', md: 'flex' },
                                                    flexDirection: 'column',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'flex-end',
                                                    flexGrow: 1,
                                                }, children: _jsx(Stepper, { id: "desktop-stepper", activeStep: activeStep, hidden: !isPayer, sx: { width: '100%', height: 40 }, children: steps.map((label) => (_jsx(Step, { sx: { ':first-child': { pl: 0 }, ':last-child': { pr: 0 } }, children: _jsx(StepLabel, { children: label }) }, label))) }) }) }), _jsx(Card, { sx: { display: { xs: 'flex', md: 'none' }, width: '100%' }, children: _jsxs(CardContent, { sx: {
                                                    display: 'flex',
                                                    width: '100%',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                }, children: [_jsxs("div", { hidden: !isPayer, children: [_jsx(Typography, { variant: "subtitle2", gutterBottom: true, children: "Total" }), _jsxs(Typography, { variant: "body1", children: ["$", enrolmentCost] })] }), _jsx(InfoMobile, {})] }) }), _jsxs(Box, { sx: {
                                                display: 'flex',
                                                flexDirection: 'column',
                                                flexGrow: 1,
                                                width: '100%',
                                                maxWidth: { sm: '100%', md: '100%' },
                                                gap: { xs: 5, md: 'none' },
                                            }, children: [_jsx(Stepper, { id: "mobile-stepper", activeStep: activeStep, alternativeLabel: true, hidden: !isPayer, sx: { display: { sm: 'flex', md: 'none' } }, children: steps.map((label) => (_jsx(Step, { sx: {
                                                            ':first-child': { pl: 0 },
                                                            ':last-child': { pr: 0 },
                                                            '& .MuiStepConnector-root': { top: { xs: 6, sm: 12 } },
                                                        }, children: _jsx(StepLabel, { sx: { '.MuiStepLabel-labelContainer': { maxWidth: '70px' } }, children: label }) }, label))) }), activeStep === steps.length ? _jsx(PostEnrolment, {}) : (_jsxs(_Fragment, { children: [stepFragment, _jsxs(Box, { sx: [
                                                                {
                                                                    display: 'flex',
                                                                    flexDirection: { xs: 'column-reverse', sm: 'row' },
                                                                    alignItems: 'end',
                                                                    flexGrow: 1,
                                                                    gap: 1,
                                                                    pb: { xs: 12, sm: 0 },
                                                                    mt: { xs: 2, sm: 0 },
                                                                    mb: '60px',
                                                                    justifyContent: 'space-between'
                                                                },
                                                            ], children: [_jsx(Button, { startIcon: _jsx(ChevronLeftRoundedIcon, {}), onClick: () => activeStep == 0 ? history.back() : prevStep(), variant: "text", sx: { display: { xs: 'none', sm: 'flex' } }, children: "Previous" }), _jsx(Button, { startIcon: _jsx(ChevronLeftRoundedIcon, {}), onClick: () => activeStep == 0 ? history.back() : prevStep(), variant: "outlined", fullWidth: true, sx: { display: { xs: 'flex', sm: 'none' } }, children: "Previous" }), _jsx(Button, { variant: "contained", color: 'info', endIcon: _jsx(ChevronRightRoundedIcon, {}), onClick: nextStep, sx: { width: { xs: '100%', sm: 'fit-content' } }, disabled: !canCompleteActiveStep || purchasing, children: activeStep === steps.length - 1 ? _jsx(_Fragment, { children: !purchasing && 'Purchase' || 'Purchasing' }) : 'Next' })] })] }))] })] })] })] }))] }));
}
