import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import Box from '@mui/material/Box';
import MuiCard from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import SingleBooking from './SingleBooking';
import GroupBooking from './GroupBooking';
import { EnrolmentBookingType, EnrolmentContext } from '../EnrolmentContextProvider';
import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
const Card = styled(MuiCard)(({ theme }) => ({
    border: '1px solid',
    borderColor: theme.palette.divider,
    width: '100%',
    '&:hover': Object.assign({ background: 'linear-gradient(to bottom right, hsla(210, 100%, 97%, 0.5) 25%, hsla(210, 100%, 90%, 0.3) 100%)', borderColor: 'primary.light', boxShadow: '0px 2px 8px hsla(0, 0%, 0%, 0.1)' }, theme.applyStyles('dark', {
        background: 'linear-gradient(to right bottom, hsla(210, 100%, 12%, 0.2) 25%, hsla(210, 100%, 16%, 0.2) 100%)',
        borderColor: 'primary.dark',
        boxShadow: '0px 1px 8px hsla(210, 100%, 25%, 0.5) ',
    })),
    [theme.breakpoints.up('md')]: {
        flexGrow: 1,
        maxWidth: `calc(50% - ${theme.spacing(1)})`,
    },
    variants: [
        {
            props: ({ selected }) => selected,
            style: Object.assign({ borderColor: theme.palette.primary.light }, theme.applyStyles('dark', {
                borderColor: theme.palette.primary.dark,
            })),
        },
    ],
}));
export default function ContactDetailsStep() {
    const { bookingType, setBookingType } = React.useContext(EnrolmentContext);
    return (_jsxs(Stack, { spacing: { xs: 3, sm: 6 }, useFlexGap: true, children: [_jsxs(FormControl, { component: "fieldset", fullWidth: true, children: [_jsx(Typography, { variant: 'h6', children: "Enrolment Options *" }), _jsxs(RadioGroup, { "aria-label": "Enrolment Type", name: "enrolmentType", value: bookingType, sx: {
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            gap: 2,
                        }, children: [_jsx(Card, { selected: bookingType === EnrolmentBookingType.single, onClick: () => setBookingType(EnrolmentBookingType.single), sx: {
                                    '.MuiCardActionArea-focusHighlight': {
                                        backgroundColor: 'transparent',
                                    },
                                    '&:focus-visible': {
                                        backgroundColor: 'action.hover',
                                    },
                                }, children: _jsx(CardActionArea, { children: _jsxs(CardContent, { sx: { display: 'flex', alignItems: 'center', gap: 1, backgroundColor: bookingType === EnrolmentBookingType.single && 'action.selected' || '' }, children: [_jsx(PersonRoundedIcon, { fontSize: "small", sx: [
                                                    (theme) => (Object.assign({ color: 'grey.400' }, theme.applyStyles('dark', {
                                                        color: 'grey.600',
                                                    }))),
                                                    bookingType === EnrolmentBookingType.single && {
                                                        color: 'primary.main',
                                                    },
                                                ] }), _jsx(Typography, { sx: { fontWeight: 'medium' }, children: "Booking Myself" })] }) }) }), _jsx(Card, { selected: bookingType === EnrolmentBookingType.group, onClick: () => setBookingType(EnrolmentBookingType.group), sx: {
                                    '.MuiCardActionArea-focusHighlight': {
                                        backgroundColor: 'transparent',
                                    },
                                    '&:focus-visible': {
                                        backgroundColor: 'action.hover',
                                    },
                                }, children: _jsx(CardActionArea, { children: _jsxs(CardContent, { sx: { display: 'flex', alignItems: 'center', gap: 1, backgroundColor: bookingType === EnrolmentBookingType.group && 'action.selected' || '' }, children: [_jsx(GroupRoundedIcon, { fontSize: "small", sx: [
                                                    (theme) => (Object.assign({ color: 'grey.400' }, theme.applyStyles('dark', {
                                                        color: 'grey.600',
                                                    }))),
                                                    bookingType === EnrolmentBookingType.group && {
                                                        color: 'primary.main',
                                                    },
                                                ] }), _jsx(Typography, { sx: { fontWeight: 'medium' }, children: "Group Booking" }), _jsx(Tooltip, { title: "Enrol multiple students, including yourself, in a single booking", style: { marginLeft: 'auto' }, children: _jsx(InfoIcon, { fontSize: 'small', color: 'action' }) })] }) }) })] })] }), bookingType === EnrolmentBookingType.single && (_jsxs(Box, { sx: { display: 'flex', flexDirection: 'column', gap: 1 }, children: [_jsx(Typography, { variant: 'h6', mb: 0, pb: 0, children: "Student Details" }), _jsx(SingleBooking, {})] })), bookingType === EnrolmentBookingType.group && (_jsxs(Box, { sx: { display: 'flex', flexDirection: 'column', gap: 1 }, children: [_jsx(Typography, { variant: 'h6', mb: 0, pb: 0, children: "Payer Details" }), _jsx(GroupBooking, {})] }))] }));
}
