import { jsx as _jsx } from "react/jsx-runtime";
import { MenuItem, OutlinedInput, Select } from "@mui/material";
export default function SACCCountrySelector(props) {
    return _jsx(Select, { id: props.name, name: props.name, input: _jsx(OutlinedInput, {}), required: props.required, disabled: props.disabled, value: props.value, onChange: (e) => props.setValue(e.target.value), MenuProps: { PaperProps: { sx: { maxHeight: '75vh' } } }, size: "small", error: props.error, children: Object.keys(saccList)
            .sort((a, b) => (saccList[a] > saccList[b] ? 1 : 0))
            .map((key) => _jsx(MenuItem, { value: key, children: saccList[key] }, key)) });
}
const saccList = {
    "1601": "Adelie Land (France)",
    "7201": "Afghanistan",
    "0918": "Africa, nfd",
    "2408": "Aland Islands",
    "3201": "Albania",
    "4101": "Algeria",
    "3101": "Andorra",
    "9201": "Angola",
    "8401": "Anguilla",
    "1600": "Antarctica",
    "8402": "Antigua and Barbuda",
    "8201": "Argentina",
    "1602": "Argentinian Antarctic Territory",
    "7202": "Armenia",
    "8403": "Aruba",
    "0917": "Asia, nfd",
    "1101": "Australia",
    "1603": "Australian Antarctic Territory",
    "1199": "Australian External Territories, nec",
    "2301": "Austria",
    "7203": "Azerbaijan",
    "8404": "Bahamas",
    "4201": "Bahrain",
    "7101": "Bangladesh",
    "3301": "Belarus",
    "2302": "Belgium",
    "8301": "Belize",
    "9101": "Benin",
    "8101": "Bermuda",
    "7102": "Bhutan",
    "8202": "Bolivia",
    "8433": "Bonaire, Sint Eustatius and Saba",
    "3202": "Bosnia and Herzegovina",
    "9202": "Botswana",
    "8203": "Brazil",
    "1604": "British Antarctic Territory",
    "5201": "Brunei Darussalam",
    "3203": "Bulgaria",
    "9102": "Burkina Faso",
    "9203": "Burundi",
    "5102": "Cambodia",
    "9103": "Cameroon",
    "8102": "Canada",
    "9104": "Cape Verde",
    "8400": "Caribbean",
    "9105": "Central African Republic",
    "8300": "Central America",
    "9100": "Central and West Africa",
    "9106": "Chad",
    "8204": "Chile",
    "1605": "Chilean Antarctic Territory",
    "6101": "China (excludes SARs and Taiwan)",
    "6100": "Chinese Asia (includes Mongolia)",
    "8205": "Colombia",
    "9204": "Comoros",
    "9108": "Congo, Democratic Republic of",
    "9107": "Congo, Republic of",
    "1501": "Cook Islands",
    "8302": "Costa Rica",
    "9111": "Cote d'Ivoire",
    "3204": "Croatia",
    "8434": "Curacao",
    "8407": "Cuba",
    "3302": "Czechia",
    "2401": "Denmark",
    "9205": "Djibouti",
    "8408": "Dominica",
    "8411": "Dominican Republic",
    "0916": "East Asia, nfd",
    "8206": "Ecuador",
    "4102": "Egypt",
    "8303": "El Salvador",
    "2102": "England",
    "9206": "Eritrea",
    "9226": "Eswatini",
    "3303": "Estonia",
    "0911": "Europe, nfd",
    "8207": "Falkland Islands",
    "2402": "Faroe Islands",
    "1502": "Fiji",
    "2403": "Finland",
    "0912": "Former USSR, nfd",
    "2303": "France",
    "8208": "French Guiana",
    "1503": "French Polynesia",
    "9113": "Gabon",
    "9114": "Gambia",
    "7204": "Georgia",
    "2304": "Germany",
    "9115": "Ghana",
    "3102": "Gibraltar",
    "3207": "Greece",
    "2404": "Greenland",
    "8412": "Grenada",
    "1401": "Guam",
    "8304": "Guatemala",
    "2107": "Guernsey",
    "9116": "Guinea",
    "9117": "Guinea-Bissau",
    "8211": "Guyana",
    "8414": "Haiti",
    "3103": "Holy See",
    "8305": "Honduras",
    "6102": "Hong Kong (SAR of China)",
    "3304": "Hungary",
    "2405": "Iceland",
    "7103": "India",
    "5202": "Indonesia",
    "0000": "Inadequately Described",
    "4203": "Iran",
    "4204": "Iraq",
    "2201": "Ireland",
    "2103": "Isle of Man",
    "4205": "Israel",
    "3104": "Italy",
    "8415": "Jamaica",
    "6201": "Japan",
    "6200": "Japan and the Koreas",
    "2108": "Jersey",
    "4206": "Jordan",
    "7205": "Kazakhstan",
    "9208": "Kenya",
    "1402": "Kiribati",
    "6202": "Korea, Democratic People's Republic of (North)",
    "6203": "Korea, Republic of (South)",
    "3216": "Kosovo",
    "0915": "Kurdistan, nfd",
    "4207": "Kuwait",
    "7206": "Kyrgyzstan",
    "5103": "Laos",
    "3305": "Latvia",
    "4208": "Lebanon",
    "9211": "Lesotho",
    "9118": "Liberia",
    "4103": "Libya",
    "2305": "Liechtenstein",
    "3306": "Lithuania",
    "2306": "Luxembourg",
    "6103": "Macau (SAR of China)",
    "3206": "North Macedonia",
    "9212": "Madagascar",
    "9213": "Malawi",
    "5203": "Malaysia",
    "7104": "Maldives",
    "9121": "Mali",
    "3105": "Malta",
    "5100": "Mainland South-East Asia",
    "5200": "Maritime South-East Asia",
    "8416": "Martinique",
    "1403": "Marshall Islands",
    "9122": "Mauritania",
    "9214": "Mauritius",
    "9215": "Mayotte",
    "1300": "Melanesia",
    "8306": "Mexico",
    "1400": "Micronesia",
    "1404": "Micronesia, Federated States of",
    "4200": "Middle East",
    "3208": "Moldova",
    "2307": "Monaco",
    "6104": "Mongolia",
    "3214": "Montenegro",
    "4104": "Morocco",
    "9216": "Mozambique",
    "5101": "Myanmar",
    "1405": "Nauru",
    "7105": "Nepal",
    "2308": "Netherlands",
    "1301": "New Caledonia",
    "1201": "New Zealand",
    "8307": "Nicaragua",
    "9123": "Niger",
    "9124": "Nigeria",
    "1504": "Niue",
    "4100": "North Africa",
    "4000": "North Africa and the Middle East",
    "2000": "North-West Europe",
    "2400": "Northern Europe",
    "2104": "Northern Ireland",
    "1406": "Northern Mariana Islands",
    "1102": "Norfolk Island",
    "2406": "Norway",
    "1000": "Oceania and Antarctica",
    "4202": "Occupied Palestinian Territories",
    "4211": "Oman",
    "7106": "Pakistan",
    "1407": "Palau",
    "8308": "Panama",
    "1302": "Papua New Guinea",
    "8212": "Paraguay",
    "8213": "Peru",
    "5204": "Philippines",
    "1513": "Pitcairn Islands",
    "3307": "Poland",
    "1500": "Polynesia (excludes Hawaii)",
    "1599": "Polynesia (excludes Hawaii), nec",
    "3106": "Portugal",
    "8421": "Puerto Rico",
    "4212": "Qatar",
    "1606": "Queen Maud Land (Norway)",
    "9218": "Reunion",
    "3211": "Romania",
    "1607": "Ross Dependency (New Zealand)",
    "3308": "Russian Federation",
    "9221": "Rwanda",
    "1505": "Samoa",
    "1506": "Samoa, American",
    "3107": "San Marino",
    "9125": "Sao Tome and Principe",
    "4213": "Saudi Arabia",
    "2105": "Scotland",
    "9126": "Senegal",
    "3215": "Serbia",
    "9223": "Seychelles",
    "9127": "Sierra Leone",
    "5205": "Singapore",
    "8435": "Sint Maarten (Dutch part)",
    "3311": "Slovakia",
    "3212": "Slovenia",
    "1303": "Solomon Islands",
    "9224": "Somalia",
    "9225": "South Africa",
    "8200": "South America",
    "8299": "South America, nec",
    "3200": "South Eastern Europe",
    "4111": "South Sudan",
    "7000": "Southern and Central Asia",
    "9200": "Southern and East Africa",
    "9299": "Southern and East Africa, nec",
    "7100": "Southern Asia",
    "3100": "Southern Europe",
    "3108": "Spain",
    "4108": "Spanish North Africa",
    "7107": "Sri Lanka",
    "8431": "St Barthelemy",
    "9222": "St Helena",
    "8422": "St Kitts and Nevis",
    "8423": "St Lucia",
    "8432": "St Martin (French part)",
    "8103": "St Pierre and Miquelon",
    "8424": "St Vincent and the Grenadines",
    "4105": "Sudan",
    "8214": "Suriname",
    "2407": "Sweden",
    "2311": "Switzerland",
    "4214": "Syria",
    "6105": "Taiwan",
    "7207": "Tajikistan",
    "9227": "Tanzania",
    "5104": "Thailand",
    "5206": "Timor-Leste",
    "9128": "Togo",
    "1507": "Tokelau",
    "8425": "Trinidad and Tobago",
    "4106": "Tunisia",
    "8426": "Turks and Caicos Islands",
    "7208": "Turkmenistan",
    "4215": "Turkiye",
    "1511": "Tuvalu",
    "9228": "Uganda",
    "3312": "Ukraine",
    "4216": "United Arab Emirates",
    "2100": "United Kingdom, Channel Islands and Isle of Man",
    "8104": "United States of America",
    "8215": "Uruguay",
    "7211": "Uzbekistan",
    "1304": "Vanuatu",
    "8216": "Venezuela",
    "5105": "Vietnam",
    "8427": "Virgin Islands, British",
    "8428": "Virgin Islands, United States",
    "2106": "Wales",
    "1512": "Wallis and Futuna",
    "2300": "Western Europe",
    "4107": "Western Sahara",
    "@@@@": "Not Specified"
};
