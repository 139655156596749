import { jsx as _jsx } from "react/jsx-runtime";
import apiFetch from '@wordpress/api-fetch';
import { addQueryArgs } from '@wordpress/url';
import { createContext, useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { ErrorContext } from './ErrorContextProvider';
export const CourseContext = createContext(null);
export const CourseProvider = ({ children }) => {
    const { setError } = useContext(ErrorContext);
    const [courseID, setCourseID] = useState(0);
    const [instanceID, setInstanceID] = useState(0);
    const [activityType, setActivityType] = useState("");
    const [courseName, setCourseName] = useState("");
    const [courseDate, setCourseDate] = useState("");
    const [courseStartDate, setCourseStartDate] = useState("");
    const [courseEndDate, setCourseEndDate] = useState("");
    const [courseCode, setCourseCode] = useState("");
    const [courseCost, setCourseCost] = useState(0);
    const [courseGST, setCourseGST] = useState("");
    const [courseDuration, setCourseDuration] = useState("");
    const [courseAddress, setCourseAddress] = useState("");
    const [maximumParticipants, setMaximumParticipants] = useState(0);
    const [vacancies, setVacancies] = useState(0);
    const [courseStartTime, setCourseStartTime] = useState("");
    const [courseLoading, setCourseLoading] = useState(false);
    const [courseLoadingError, setCourseLoadingError] = useState(false);
    const [courseLoadingErrorMsg, setCourseLoadingErrorMsg] = useState("There was an error loading this course, please contact support.");
    useEffect(() => {
        const searchParams = new URLSearchParams(document.location.search);
        const course_id = searchParams.get("course_id");
        const course_type = searchParams.get("course_type");
        const instance_id = searchParams.get("instance_id");
        if (!course_id || !course_type || !instance_id) {
            setCourseLoadingError(true);
            setCourseLoadingErrorMsg("Course parameters were not provided");
            return;
        }
        loadCourse(course_id, course_type, instance_id);
    }, []);
    const loadCourse = (course_id, course_type, instance_id) => {
        setCourseLoading(true);
        apiFetch({
            path: addQueryArgs(window.ati_ax_globals.enrolment.loadAction, {
                course_id: course_id,
                course_type: course_type,
                instance_id: instance_id,
                nonce: window.ati_ax_globals.enrolment.nonce
            }),
        })
            .then(res => {
            const data = res;
            setCourseID(data.course.ID);
            setInstanceID(data.instance.INSTANCEID);
            setActivityType(data.course.TYPE);
            setCourseName(data.course.NAME);
            setCourseCode(data.course.CODE);
            setCourseCost(data.course.COST);
            setCourseGST(data.course.GST_TYPE);
            if (data.course.TYPE == 'w') {
                setCourseStartDate(data.instance.STARTDATE);
                setCourseEndDate(data.instance.FINISHDATE);
                setCourseDuration(data.course.DURATION);
                setCourseDate(data.instance.DATEDESCRIPTOR);
                setMaximumParticipants(data.instance.MAXPARTICIPANTS);
                setVacancies(data.instance.PARTICIPANTVACANCY);
                setCourseAddress(data.instance.ADDRESS);
            }
            else if (data.course.TYPE == 'p') {
                // Handle "online" courses which dont have start and finish dates
                const startDate = dayjs();
                setCourseStartDate(startDate.format('YYYY-MM-DD HH:mm'));
                const finishDate = dayjs().add(parseInt(data.instance.DURATION), data.instance.DURATIONTYPE.toLowerCase());
                setCourseEndDate(finishDate.format('YYYY-MM-DD HH:mm'));
                setCourseDuration(`${data.instance.DURATION} ${data.instance.DURATIONTYPE.toLowerCase()}s`);
                setCourseDate(dayjs().format('D MMMM YYYY'));
                setMaximumParticipants(99);
                setVacancies(99);
                setCourseAddress("Online");
            }
        })
            .catch((e) => {
            setError(new Error(e.message));
            return;
        })
            .finally(() => {
            setCourseLoading(false);
        });
    };
    useEffect(() => {
        // start and end date are in 'YYYY-MM-DD HH:MM' so we need to parse them and convert to a human friendly format.
        // I _HATE_ this...
        const startTime = courseStartDate.split(' ')[1];
        setCourseStartTime(startTime);
    }, [courseDate]);
    return (_jsx(CourseContext.Provider, { value: {
            courseID,
            setCourseID,
            instanceID,
            setInstanceID,
            activityType,
            setActivityType,
            courseName,
            setCourseName,
            courseDate,
            setCourseDate,
            courseStartDate,
            setCourseStartDate,
            courseEndDate,
            setCourseEndDate,
            courseCode,
            setCourseCode,
            courseCost,
            setCourseCost,
            courseGST,
            setCourseGST,
            courseDuration,
            setCourseDuration,
            courseAddress,
            setCourseAddress,
            maximumParticipants,
            setMaximumParticipants,
            vacancies,
            setVacancies,
            courseStartTime,
            loadCourse,
            courseLoading,
            courseLoadingError,
            courseLoadingErrorMsg,
            setCourseLoadingErrorMsg
        }, children: children }));
};
