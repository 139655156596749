var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import apiFetch from "@wordpress/api-fetch";
import { addQueryArgs } from "@wordpress/url";
import { useContext, useEffect, useState } from "react";
import { EnrolmentBookingType, EnrolmentContext } from "./EnrolmentContextProvider";
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { Alert, AlertTitle, LinearProgress, Typography } from "@mui/material";
import PostEnrolmentForm from "./components/PostEnrolmentForm";
import { ErrorContext } from "./ErrorContextProvider";
import { CourseContext } from "./CourseContextProvider";
// Keep this outside the render function so it wont be recreated on re-render
const stripePromise = loadStripe(window.ati_ax_globals.enrolment.stripePK);
function successRedirect(activityType, bookingType) {
    let location = "/";
    if (bookingType == EnrolmentBookingType.group) {
        location = window.ati_ax_globals.postEnrolment.successRedirectG;
    }
    else if (bookingType == EnrolmentBookingType.single) {
        if (activityType == 'w') {
            location = window.ati_ax_globals.postEnrolment.successRedirectSW;
        }
        else if (activityType == 'p') {
            location = window.ati_ax_globals.postEnrolment.successRedirectSP;
        }
    }
    window.location.href = location;
}
export default function PostEnrolment() {
    const { activityType } = useContext(CourseContext);
    const { updateContactFormCompleted, bookingType } = useContext(EnrolmentContext);
    const searchParams = new URLSearchParams(document.location.search);
    const token = searchParams.get("token");
    if (token) {
        if (updateContactFormCompleted) {
            successRedirect(activityType, bookingType);
            return _jsx(_Fragment, { children: "Please wait while we redirect you" });
        }
        else {
            return _jsx(PostEnrolmentForm, {});
        }
    }
    return (_jsx(Elements, { stripe: stripePromise, options: {}, children: _jsx(PostEnrolmentFragment, {}) }));
}
const PostEnrolmentFragment = () => {
    const stripe = useStripe();
    const { setError } = useContext(ErrorContext);
    const { activityType } = useContext(CourseContext);
    const { bookingType, enrolmentCompleted, updateContactFormCompleted, attendees, emailAddress, enrolmentFinalised, setEnrolmentFinalised } = useContext(EnrolmentContext);
    const [paymentCompleted, setPaymentCompleted] = useState(false); // This tracks if the payment was successful.
    const [paymentError, setPaymentError] = useState("");
    // When the payment has been completed, finalise the booking
    useEffect(() => {
        if (!paymentCompleted)
            return;
        const finaliseEnrolment = () => __awaiter(void 0, void 0, void 0, function* () {
            const searchParams = new URLSearchParams(document.location.search);
            const paymentIntent = searchParams.get("payment_intent");
            const res = yield apiFetch({
                path: addQueryArgs(window.ati_ax_globals.postEnrolment.finaliseEnrolment, {
                    paymentIntent: paymentIntent
                })
            })
                .catch((e) => {
                setError(new Error(e.message));
            });
            setEnrolmentFinalised(true);
        });
        finaliseEnrolment();
    }, [paymentCompleted]);
    useEffect(() => {
        if (enrolmentCompleted)
            return;
        const confirmPayment = () => __awaiter(void 0, void 0, void 0, function* () {
            const searchParams = new URLSearchParams(document.location.search);
            const paymentIntentClientSecret = searchParams.get("payment_intent_client_secret");
            if (!stripe || !paymentIntentClientSecret)
                return;
            const { paymentIntent, error } = yield stripe.confirmCardPayment(paymentIntentClientSecret);
            if (error) {
                setPaymentError(error.message);
            }
            else if (paymentIntent && paymentIntent.status === 'succeeded') {
                setPaymentCompleted(true);
            }
        });
        confirmPayment();
    }, [stripe, enrolmentCompleted]);
    const PaymentError = () => (_jsxs(Alert, { severity: "error", children: [_jsx(AlertTitle, { children: "Payment Error" }), paymentError] }));
    const EnrolmentCompleted = () => {
        successRedirect(activityType, bookingType);
        return _jsx(_Fragment, { children: "Please wait while we rediect you." });
    };
    const ConfirmingPayment = () => (_jsxs(_Fragment, { children: [_jsx(LinearProgress, {}), _jsx(Typography, { variant: "h5", children: "Confirming Payment" }), _jsx(Typography, { variant: "body1", sx: { color: 'text.secondary' }, children: "Please wait while we confirm your payment. Please do not close this page." })] }));
    const FinaliseEnrolment = () => {
        if (enrolmentFinalised)
            return _jsx(FormOrCompleted, {});
        else
            return (_jsxs(_Fragment, { children: [_jsx(LinearProgress, {}), _jsx(Typography, { variant: "h5", children: "Finalising Enrolment" }), _jsx(Typography, { variant: "body1", sx: { color: 'text.secondary' }, children: "Please wait while we finalise your enrolment. Please do not close this page." })] }));
    };
    const FormOrCompleted = () => {
        if (updateContactFormCompleted)
            successRedirect(activityType, bookingType);
        if (bookingType == EnrolmentBookingType.single) {
            return _jsx(PostEnrolmentForm, {});
        }
        else {
            // For group bookings we only show the data collection form if the payer is attending
            let show = false;
            attendees.forEach((a) => {
                if (a.emailAddress == emailAddress)
                    show = true;
            });
            if (show) {
                return _jsx(PostEnrolmentForm, {});
            }
        }
        return _jsx(_Fragment, { children: "Please wait while we redirect you" });
    };
    return (_jsxs(_Fragment, { children: [paymentError && _jsx(PaymentError, {}), enrolmentCompleted && _jsx(EnrolmentCompleted, {}), paymentCompleted && _jsx(FinaliseEnrolment, {}) || !paymentError && !enrolmentCompleted && _jsx(ConfirmingPayment, {})] }));
};
const DataCollectionFragment = () => {
    return _jsx(PostEnrolmentForm, {});
};
