import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid2';
import OutlinedInput from '@mui/material/OutlinedInput';
import { styled } from '@mui/material/styles';
import { MenuItem, Select, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { EnrolmentContext } from '../EnrolmentContextProvider';
import ABNLookup from './ABNLookup';
import { checkEmail } from '../../utils';
const FormGrid = styled(Grid)(() => ({
    display: 'flex',
    flexDirection: 'column',
}));
export default function GroupBooking() {
    const { firstName, setFirstName, lastName, setLastName, emailAddress, setEmailAddress, phoneNumber, setPhoneNumber, ABN, reference, setReference } = useContext(EnrolmentContext);
    const [emailAddressError, setEmailAddressError] = useState(false);
    return (_jsxs(Grid, { container: true, spacing: 3, children: [_jsxs(FormGrid, { size: { xs: 12, md: 6 }, children: [_jsx(FormLabel, { htmlFor: "first_name", required: true, children: "First name" }), _jsx(OutlinedInput, { id: "first_name", name: "first_name", type: "name", placeholder: "John", autoComplete: "first name", required: true, size: "small", value: firstName, onChange: (e) => setFirstName(e.target.value) })] }), _jsxs(FormGrid, { size: { xs: 12, md: 6 }, children: [_jsx(FormLabel, { htmlFor: "last_name", required: true, children: "Last name" }), _jsx(OutlinedInput, { id: "last_name", name: "last_name", type: "last_name", placeholder: "Snow", autoComplete: "last name", required: true, size: "small", value: lastName, onChange: (e) => setLastName(e.target.value) })] }), _jsxs(FormGrid, { size: { xs: 12, md: 6 }, children: [_jsx(FormLabel, { htmlFor: "email_address", required: true, children: "Email Address" }), _jsx(OutlinedInput, { id: "email_address", name: "email_address", type: "last_name", placeholder: "J.Snow@mail.com", autoComplete: "email address", required: true, size: "small", error: emailAddressError, onChange: (e) => {
                            setEmailAddress(e.target.value);
                            setEmailAddressError(!checkEmail(e.target.value));
                        } }), _jsx(FormLabel, { error: true, hidden: !emailAddressError, children: "Please enter a valid email address" })] }), _jsxs(FormGrid, { size: { xs: 12, md: 6 }, children: [_jsx(FormLabel, { htmlFor: "phoneNumber", required: true, children: "Phone Number" }), _jsx(OutlinedInput, { id: "phoneNumber", name: "phoneNumber", type: "last_name", placeholder: "04XX XXX XXX or (0X) XXXX XXXX", autoComplete: "phone number", required: true, size: "small", value: phoneNumber, onChange: (e) => setPhoneNumber(e.target.value) })] }), _jsxs(FormGrid, { size: { xs: 12, md: 6 }, children: [_jsx(FormLabel, { htmlFor: "organisation", children: "Organisation" }), _jsx(ABNLookup, {})] }), _jsx(FormGrid, { size: { xs: 12, md: 6 }, children: ABN &&
                    _jsxs(_Fragment, { children: [_jsx(FormLabel, { htmlFor: "abn", children: "ABN" }), _jsx(OutlinedInput, { id: "abn", name: "abn", type: "last_name", placeholder: "", autoComplete: "", size: "small", value: ABN, disabled: true })] })
                    ||
                        _jsx(_Fragment, {}) }), _jsxs(FormGrid, { size: { xs: 12, md: 12 }, children: [_jsx(FormLabel, { htmlFor: "reference", required: true, children: "How did you hear about us?" }), _jsxs(Select, { id: "reference", name: "reference", size: "small", input: _jsx(OutlinedInput, {}), required: true, value: reference, onChange: (e) => setReference(e.target.value), children: [_jsx(MenuItem, { value: "", children: "Select" }), _jsx(MenuItem, { value: "9910", children: "Facebook" }), _jsx(MenuItem, { value: "9909", children: "Google Ads" }), _jsx(MenuItem, { value: "9911", children: "Instagram" }), _jsx(MenuItem, { value: "9901", children: "Internet Search" }), _jsx(MenuItem, { value: "9912", children: "Linkedin" }), _jsx(MenuItem, { value: "9905", children: "Referral" }), _jsx(MenuItem, { value: "9908", children: "Word of Mouth" })] })] }), _jsx(FormGrid, { size: { xs: 12, md: 12 }, children: _jsx(Typography, { variant: 'body1', children: "Please ensure the person making the booking enters their contact details. You can then easily add or update individual details, including your own, for all participants on the next page." }) })] }));
}
